<template>
  <div class="ip-networks">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        class="text-md-right"
        cols="12"
        md="6"
      >
        <v-btn
          v-if="can('ip-network-create')"
          color="primary"
          outlined
          @click="showImportDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiImport }}
          </v-icon>
          Import
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card>
        <v-card-title>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <refresh-button
                :loading="isIpNetworksRefreshing"
                @click="refreshIpNetworks()"
              />
            </v-col>
            <v-col
              md="6"
              sm="12"
              class="text-center"
            >
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
            <v-spacer class="d-none d-md-inline" />
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="ipNetworks"
          :loading="isIpNetworksLoading"
          :server-items-length="pagination.totalItems"
          :options.sync="options"
          :footer-props="footerProps"
          class="clickable-data-table"
          @click:row="onRowClick"
        >
          <template v-slot:item.station="{ item }">
            {{ item.router.station.name }} Router
          </template>
          <template v-slot:item.range="{ item }">
            {{ item.first_assignable_address }} - {{ item.last_assignable_address }}
          </template>
          <template v-slot:item.used="{ item }">
            {{ calculateUsage(item.used_addresses, item.total_assignable_addresses) }}% ({{ item.used_addresses }} / {{ item.total_assignable_addresses }})
            <v-progress-linear
              :value="calculateUsage(item.used_addresses, item.total_assignable_addresses)"
              height="8"
              rounded
            >
            </v-progress-linear>
          </template>
        </v-data-table>
      </v-card>
    </v-expand-transition>
    <import-data-dialog
      v-model="showImportDialog"
      title="Import IP Networks"
      url="/import-from-router/ip-networks"
      :show-import-type-selection="false"
      default-import-type="router"
      @imported="refreshIpNetworks"
      @close="showImportDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mdiMagnify, mdiImport } from '@mdi/js'
import { mapGetters } from 'vuex'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import ImportDataDialog from '@/components/dialogs/ImportDataDialog.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    breadcrumb: BreadCrumb,
    SearchInput,
    RefreshButton,
    ImportDataDialog,
  },
  mixins: [hasPermission],
  data() {
    return {
      showImportDialog: false,
      icons: { mdiMagnify, mdiImport },
      breadcrumbs: [
        { text: 'Dashboard', disabled: false, to: { name: 'dashboard' } },
        { text: 'Networking', disabled: true },
        { text: 'IP Networks', disabled: true },
      ],
      ipNetworks: [],
      isIpNetworksLoading: true,
      isIpNetworksRefreshing: false,
      search: '',
      searchFilters: ['Network Address', 'Interface'],
      searchColumn: 'Network Address',
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ['created_at'],
        sortDesc: [false],
      },
      footerProps: {
        itemsPerPageOptions: [10, 20, 50],
      },
      pagination: {
        totalItems: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    headers() {
      const baseHeaders = [
        { text: 'Network Address', value: 'network_address' },
        { text: 'Prefix length', value: 'prefix_length' },
        { text: 'IP Range', value: 'range' },
        { text: 'Used', value: 'used', width: '200px' },
        { text: 'Interface', value: 'interface' },
        { text: 'Comment', value: 'comment' },
      ]

      if (this.selectedStation === 'all') {
        baseHeaders.push({ text: 'Router', value: 'station' })
      }

      return baseHeaders
    },
  },

  watch: {
    options: {
      handler() {
        this.getIpNetworks()
      },
      deep: true,
    },
    search: {
      handler: _.debounce(function () {
        this.getIpNetworks()
      }, 500),
    },
    selectedStation() {
      this.refreshIpNetworks()
    },
  },
  mounted() {
    this.getIpNetworks()
  },
  methods: {
    getIpNetworks() {
      this.isIpNetworksLoading = true
      axios
        .get('ip-networks', {
          params: {
            search: this.search,
            column: this.searchColumn,
            stationId: this.selectedStation,
            ...this.options,
          },
        })
        .then(response => {
          this.ipNetworks = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isIpNetworksLoading = false
          this.isIpNetworksRefreshing = false
        })
    },
    refreshIpNetworks() {
      this.isIpNetworksRefreshing = true
      this.getIpNetworks()
    },
    calculateUsage(used_addresses, total_assignable_addresses) {
      // Calculate the percentage
      const value = (used_addresses / total_assignable_addresses) * 100

      // Use toFixed(2) to format it as a number with 2 decimal places
      let result = value.toFixed(2)

      // Remove trailing zeros after the decimal point and the point itself if it's a whole number
      if (result.indexOf('.00') > -1) {
        result = result.substring(0, result.indexOf('.00'))
      }

      return result
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    onRowClick(item) {
      this.$router.push({ name: 'ip-networks.show', params: { id: item.id } })
    },
  },
}
</script>
